import { URLS } from 'config';
import { ApiMutationConfig, ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import {
  AccountAssignmentResponse,
  PutAccountAssignmentsBody,
  UserPathParams,
} from '../types';

type GetUserAccountsConfig = ApiQueryConfig<
  AccountAssignmentResponse,
  UserPathParams
>;

type PutUserAccountsConfig = ApiMutationConfig<
  PutAccountAssignmentsBody,
  UserPathParams
>;

const url = `${URLS.account}/v1/users/$userId/accounts`;

export const getUserAccounts = createApiEndpoint<GetUserAccountsConfig>({
  url,
  method: 'GET',
});

export const putUserAccount = createApiEndpoint<PutUserAccountsConfig>({
  url,
  method: 'PUT',
});
