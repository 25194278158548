import { URLS } from 'config';
import { ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { InviteShort } from '../types';

type GetInviteQueryParams = {
  token: string;
};

type GetInviteResponse = InviteShort;

type GetVerifyTokenConfig = ApiQueryConfig<
  GetInviteResponse,
  unknown,
  GetInviteQueryParams
>;

const url = `${URLS.account}/v1/invites/verify`;

export const getInvitesVerify = createApiEndpoint<GetVerifyTokenConfig>({
  url,
  method: 'GET',
});
