import { FIVE_MINUTES_IN_MS } from 'config';
import { decode } from 'jsonwebtoken';
import * as R from 'ramda';
import { Auth } from '../types';

/**Returns true when passed valid auth and userDetails */
export function getAuthIsValid(auth: Auth | null): boolean {
  if (!auth) {
    return false;
  }

  const expiredTime = auth.expiry ?? 0;
  const now = new Date().valueOf();
  const authTimeRemaining = expiredTime - now;

  // our apis don't seems to accept tokens that are just about to expire
  // so we make sure it will be valid for more than five minutes
  const notExpired = authTimeRemaining > FIVE_MINUTES_IN_MS;

  return notExpired;
}

/**Returns the userId for a given token */
export function getUserIdFromToken(token?: string): string {
  if (!token) return '';

  return R.compose(R.prop('sub'), decode)(token);
}

/**Returns the username for a given token */
export function getUserNameFromToken(token?: string): string {
  if (!token) return '';

  return R.compose(R.prop('username'), decode)(token);
}
