import { LogoutOutlined } from '@ant-design/icons';
import { useQueryString } from '@x/hooks';
import { UserContext } from '@x/stores';
import { Button, Card, Space, Typography } from 'antd';
import { UnauthorizedPage } from 'components/Layout/UnauthorizedPage';
import React, { useContext } from 'react';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';

export function InvalidInvite({
  message,
}: {
  message?: string;
}): React.JSX.Element {
  const auth = useAuth();
  const { logout } = useContext(UserContext);
  const { token } = useQueryString();

  function signOut() {
    logout(true);
    auth.signoutRedirect({
      post_logout_redirect_uri: `${document.location.origin}/invite?token=${token}`,
    });
  }

  return (
    <UnauthorizedPage top={'35%'}>
      <Card style={{ textAlign: 'center' }}>
        <Space direction="vertical">
          <Typography.Title level={3}>Incorrect User</Typography.Title>
          {message && (
            <Typography.Text type="secondary">{message}</Typography.Text>
          )}
          {!auth.user ? (
            <Typography
              style={{
                fontWeight: 'lighter',
                paddingBottom: 20,
                textAlign: 'center',
              }}
            >
              Already have an account? <Link to="/">Sign in</Link>
            </Typography>
          ) : (
            <Typography
              style={{
                fontWeight: 'lighter',
                paddingBottom: 20,
                textAlign: 'center',
              }}
            >
              <Button type="link" onClick={signOut}>
                <LogoutOutlined /> Logout
              </Button>
            </Typography>
          )}
        </Space>
      </Card>
    </UnauthorizedPage>
  );
}
