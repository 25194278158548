import { Box } from 'components/Box';
import React from 'react';
import styles from './unauthorized.module.less';

export function UnauthorizedPage({
  top,
  children,
}: {
  top: string;
  children: React.JSX.Element;
}): React.JSX.Element {
  return (
    <div className={styles.loggedOut} data-testid="loggedOut">
      <div className={styles.logo} />
      <div className={styles.imageContainer}>
        <img src="/triangles.png" alt="" />
      </div>
      <Box
        position="absolute"
        left={0}
        right={0}
        bottom={[0, 0, 'initial']}
        top={['200px', '200px', top]}
      >
        <Box display={['block', 'block', 'none']}>
          <Box position="absolute" top="-50px" left={0} right={0}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              height="50px"
              width="100%"
            >
              <polygon fill="white" points="0,100 100,0 100,100" />
              <polygon fill="white" points="0,0 100,100 0,100" />
            </svg>
          </Box>
        </Box>
        <Box mx="auto" height="100%" width={['100%', '100%', 450]}>
          {children}
        </Box>
      </Box>
    </div>
  );
}
