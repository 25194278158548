export const GAIN_SIGHT_KEY = process.env.REACT_APP_GAIN_SIGHT_KEY;

function setupGainsight(win, doc, url, key, co) {
  if (!key) return;

  win.aptrinsic =
    win.aptrinsic ||
    function () {
      (win.aptrinsic.q = win.aptrinsic.q || []).push(arguments);
    };

  win.aptrinsic.p = key;
  win.aptrinsic.c = co;

  const refScript = doc.createElement('script');

  refScript.async = true;
  refScript.src = url + '?a=' + key;

  doc.head.append(refScript);
}

setupGainsight(
  window,
  document,
  'https://web-sdk.aptrinsic.com/api/aptrinsic.js',
  GAIN_SIGHT_KEY,
);

export const aptrinsic = window.aptrinsic;
