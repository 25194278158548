import { portalToken } from '@x/api';
import { decode } from 'jsonwebtoken';
import * as R from 'ramda';
import { useMemo } from 'react';
import { UserToken } from '../types';

export function useUserToken(): UserToken | undefined {
  const rawToken = portalToken();

  return useMemo(() => R.when(R.identity, decode)(rawToken), [rawToken]);
}
