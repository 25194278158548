import { WORK_REQUEST_STATUS_FILTERS } from './config/constants';

export type DefinitionWorkRequest = {
  batchCounts: BatchCounts;
  createdOn: string;
  id: number;
  itemCounts: ItemCounts;
  key: string;
  latestActivityOn: string;
  latestItemOn: string;
  parentWorkRequestId?: number;
};

export type ItemCounts = {
  canceled: number;
  failed: number;
  succeeded: number;
  open: number;
};

export type BatchCounts = {
  open: number;
  closed: number;
};

export type WorkRequestItem = {
  id: number;
  key: string;
  type: ItemType;
  resolutionStatus: ResolutionStatus | null;
  createdOn: Date;
  resolvedOn: Date | null;
  metaData: Record<string, unknown> | null;
  resolutionData: Record<string, unknown> | null;
};

export type ItemType = 'cache_record' | 'action';

export enum ResolutionStatus {
  Successful = 'successful',
  Failed = 'failed',
  Canceled = 'canceled',
}

type ResolutionStatusFilterTuple = typeof WORK_REQUEST_STATUS_FILTERS;

export type ResolutionStatusFilter = ResolutionStatusFilterTuple[number];

export type BatchStatusFilter = 'open' | 'closed';

export type WorkRequestCollection = {
  id: number;
  key: string;
  createdOn: Date;
  itemType: ItemType;
  itemCounts: ItemCounts;
};

export type WorkRequestType = {
  displayName: string | undefined;
  id: number;
  key: string;
  inProgressRequests: number;
  isBatchingDefinition: boolean;
  totalRequests: number;
  limit?: number;
  requiresFinalize?: boolean;
  timeoutInSeconds?: number;
  integrationWorkRequestDefinitionId?: number;
  isOverridden: boolean;
};

export type PostWorkRequestTypesBody = {
  key: string;
  displayName: string;
  limit?: number;
  requiresFinalize: boolean;
  timeoutInSeconds: number;
  isBatchingDefinition: boolean;
};

export type PutWorkRequestTypeBody = {
  displayName?: string;
  limit?: number;
  requiresFinalize?: boolean;
  timeoutInSeconds?: number;
  isBatchingDefinition?: boolean;
  isOverridden?: boolean;
};

export type WorkRequest = {
  workItemKey: string;
  workspaceId: number;
  workRequestDefinitionId: number;
  workRequestDefinitionKey: string;
  workRequestKey: string;
  workRequestId: number;
  parentWorkRequestId: number | null;
  createdOn: string;
  finishedOn: string;
  lastActivityDate: string;
  resolutionStatus: ResolutionStatus | null;
  isBatchingDefinition: boolean;
};

export type WorkRequestsPathParams = {
  $workspaceId: number;
  $definitionId: number;
};
