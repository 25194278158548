import { useAuth } from 'react-oidc-context';
import { getUserIdFromToken } from '../helpers';

export function useUserId(): string {
  const auth = useAuth();
  const token = auth?.user?.access_token;

  return getUserIdFromToken(token);
}

export default useUserId;
