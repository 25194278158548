import { useUserToken } from 'features/user/hooks';
import React, { useEffect } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Route, useHistory } from 'react-router-dom';

const AuthenticatedRoute = ({
  children,
  ...props
}: {
  children?: React.JSX.Element;
}): React.JSX.Element | null => {
  const auth = useAuth();
  const token = useUserToken();
  const history = useHistory();

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect();
    }

    if (auth.error || !token) {
      history.replace('/login-error');
    }
  }, [auth, token, history]);

  return <Route {...props}>{children}</Route>;
};

export default AuthenticatedRoute;
