import * as R from 'ramda';
import { INVALID_URL } from '../config';
import { UseAxiosOptions } from '../types';
import { isInvalidPathParam } from './predicates';

const throwWhenUndefined = R.curry((errorMsg: string, value: unknown) =>
  R.when(isInvalidPathParam, () => {
    throw new Error(errorMsg);
  })(value),
);

const getVariableValue = R.curry((obj: Record<string, unknown>, key: string) =>
  R.compose(
    throwWhenUndefined(`${key} is not defined in ${JSON.stringify(obj)}`),
    R.prop(R.__, obj),
  )(key),
);

export const getUrl = R.curry(
  (paramaterizedUrl: string, obj: Record<string, unknown>) =>
    R.compose(
      R.join('/'),
      R.map(R.when(R.startsWith('$'), getVariableValue(obj))),
      R.split('/'),
    )(paramaterizedUrl),
);

export const getDerivedUrl = ({
  url,
  ...props
}: UseAxiosOptions<any, any, any>): string =>
  R.tryCatch(getUrl(url), R.always(INVALID_URL))(props);
