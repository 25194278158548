import { Workspace } from 'api/types';
import * as R from 'ramda';
import useLocalStorage from 'use-local-storage-state';
import { saveWorkspacesToLocalStorage } from '../helpers';
import { useAuthenticatedUser } from './useAuthenticatedUser';

type RecentWorkspaces = {
  recentWorkspaces: Workspace[];
  addRecentWorkspace: (workspace: Workspace) => void;
};

export function useRecentWorkspaces(): RecentWorkspaces {
  const user = useAuthenticatedUser();
  const recentWorkspacesKey = `recentWorkspaces-${user.id}`;
  const [recentWorkspaces = [], setRecentWorkspaces] =
    useLocalStorage<Workspace[]>(recentWorkspacesKey);

  function addRecentWorkspace(workspace: Workspace) {
    R.compose(
      setRecentWorkspaces,
      R.tap(saveWorkspacesToLocalStorage(recentWorkspacesKey)),
      R.take(10),
      R.uniqBy(R.prop('id')),
      R.prepend(workspace),
    )(recentWorkspaces);
  }

  return {
    recentWorkspaces,
    addRecentWorkspace,
  };
}
