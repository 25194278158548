import { URLS } from 'config';
import { ApiMutationConfig, ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import {
  AccountWorkpacePathParams,
  PutAccountWorkspaceBody,
  Workspace,
} from '../types';

type GetAccountWorkspaceConfig = ApiQueryConfig<
  Workspace,
  AccountWorkpacePathParams
>;
type PutAccountWorkspaceConfig = ApiMutationConfig<
  PutAccountWorkspaceBody,
  AccountWorkpacePathParams
>;
const url = `${URLS.account}/v1/workspaces/$workspaceId`;

export const getWorkspace = createApiEndpoint<GetAccountWorkspaceConfig>({
  url,
  method: 'GET',
});

export const putWorkspace = createApiEndpoint<PutAccountWorkspaceConfig>({
  url,
  method: 'PUT',
});
