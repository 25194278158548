import { BasePaginationParams } from '@x/types';

export enum ConnectorBuilderServiceType {
  ConformedSynchronizer = 'conformedSynchronizer',
  Uploader = 'uploader',
  Downloader = 'downloader',
  ServiceSynchronizer = 'serviceSynchronizer',
  Event = 'event',
  Universal = 'universal',
  IntegrationRunner = 'integrationRunner',
}

export enum ConnectorBuilderServiceRunnerType {
  InProc = 'inProc',
  Outside = 'delegateToOutsideServiceRunner',
  Host = 'delegateToServiceRunnerHost',
  HostCoordinator = 'delegateToServiceRunnerHostCoordinator',
  Kubernetes = 'delegateToKubernetesJob',
  Kubernetes25 = 'delegateToKubernetesJob25Weight',
  Kubernetes50 = 'delegateToKubernetesJob50Weight',
  Kubernetes75 = 'delegateToKubernetesJob75Weight',
  Unknown = 'unknown',
}

export type ConnectorBuilderService = {
  id: number;
  name: string;
  connectorName: string | null;
  connectorKey: string | null;
  type: ConnectorBuilderServiceType;
  isConnectorService: boolean;
  runnerType: ConnectorBuilderServiceRunnerType;
};

type ServiceProperties = keyof Omit<ConnectorBuilderService, 'id'>;

export type GetConnectorBuilderServicesQueryParams = BasePaginationParams & {
  orderBy?: ServiceProperties;
  filter?: ServiceProperties;
  search?: string;
  ascending?: boolean;
};

export type ConnectorBuilderServicesPostBody = {
  name?: string | null;
  connectorKey?: string | null;
  type: ConnectorBuilderServiceType;
  isConnectorService: boolean;
  runnerType: ConnectorBuilderServiceRunnerType;
};
