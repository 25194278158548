import { CloseCircleTwoTone } from '@ant-design/icons';
import { xTheme } from '@x/styles';
import { Button, Card, Typography } from 'antd';
import { UnauthorizedPage } from 'components/Layout/UnauthorizedPage';
import { VSpace } from 'components/VSpace';
import React from 'react';
import { useAuth } from 'react-oidc-context';
import { useHistory } from 'react-router-dom';

const { colors } = xTheme;

export function LoginError({
  message,
}: {
  message: string;
}): React.JSX.Element {
  const history = useHistory();
  const auth = useAuth();

  const tryParseError = getErrorMessage();

  function getErrorMessage() {
    try {
      const parsedMessage = message.substring(
        message.indexOf(':') + 1,
        message.length,
      );

      const errorJson = JSON.parse(parsedMessage);

      return errorJson;
    } catch {
      return { message: 'Authentication Error' };
    }
  }

  return (
    <UnauthorizedPage top={'35%'}>
      <Card style={{ textAlign: 'center' }}>
        <VSpace>
          <CloseCircleTwoTone
            twoToneColor={colors.error}
            style={{ fontSize: '32px' }}
          />
          <Typography.Title level={3}>Ooops!</Typography.Title>
          <Typography.Text type="secondary">
            {tryParseError.message}
          </Typography.Text>
          <Typography
            style={{
              fontWeight: 'lighter',
              paddingBottom: 20,
              textAlign: 'center',
            }}
          >
            Take an action below.
          </Typography>{' '}
          {tryParseError.idToken ? (
            <Button
              type="primary"
              onClick={() =>
                auth.signoutRedirect({
                  id_token_hint: tryParseError.idToken,
                  post_logout_redirect_uri: `${document.location.origin}/logout`,
                })
              }
            >
              Logout
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() => {
                history.replace('/');
              }}
            >
              Try again
            </Button>
          )}
        </VSpace>
      </Card>
    </UnauthorizedPage>
  );
}
