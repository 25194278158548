import styled from '@emotion/styled';
import css from '@styled-system/css';
import { xTheme } from '@x/styles';
import * as R from 'ramda';
import {
  background,
  border,
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  system,
  typography,
  variant,
} from 'styled-system';

const additionalCssProps = system({
  cursor: true,
  transform: true,
  transition: true,
  areas: { property: 'gridTemplateAreas' },
  area: { property: 'gridArea' },
  cols: { property: 'gridTemplateColumns' },
  rows: { property: 'gridTemplateRows' },
  colSpan: {
    property: 'gridColumnEnd',
    transform: R.o(R.concat('span '), R.toString),
  },
  rowSpan: {
    property: 'gridRowEnd',
    transform: R.o(R.concat('span '), R.toString),
  },
  gap: { property: 'gridGap', scale: 'space' },
  autoCols: { property: 'gridAutoColumns' },
  autoRows: { property: 'gridAutoRows' },
  autoFlow: { property: 'gridAutoFlow' },
  hover: {
    property: '&:hover',
    transform: R.compose(
      R.apply(R.__, [xTheme]),
      css,
      R.prop('hover'),
      R.nthArg(2),
    ),
  } as any,
  before: {
    property: '&:before',
    transform: R.compose(
      R.apply(R.__, [xTheme]),
      css,
      R.prop('before'),
      R.nthArg(2),
    ),
  } as any,
  after: {
    property: '&:after',
    transform: R.compose(
      R.apply(R.__, [xTheme]),
      css,
      R.prop('after'),
      R.nthArg(2),
    ),
  } as any,
});

export const Box = styled('div')(
  { boxSizing: 'border-box' },
  compose(
    space,
    color,
    typography,
    layout,
    flexbox,
    grid,
    background,
    border,
    position,
    shadow,
    additionalCssProps,
  ),
  variant({
    variants: {
      grid: {
        display: 'grid',
        gridGap: 2,
      },
      flex: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  }),
);

export default Box;
