import { Space, SpaceProps } from 'antd';
import React from 'react';

export function VSpace(props: SpaceProps): React.JSX.Element {
  return (
    <Space direction="vertical" style={{ display: 'flex' }} {...props}>
      {props.children}
    </Space>
  );
}
