import { PaginatedResponse } from '@x/types';
import { WorkspaceType } from 'api/types';
import { XTreeDataNode } from 'components/RyvitTree/types';
import { ActivityLog } from 'features/activityLogs/types';
import { ConfigPropertyTypes, SchemaProperty } from 'features/schemaBuilder';
import { IntegrationFeatureSummary } from './integrationFeatureTypes';

export type GetIntegrationsResponse = PaginatedResponse<IntegrationSummary>;

export type GetIntegrationRegistrationsResponse =
  PaginatedResponse<IntegrationRegistrationSummary>;

type StatusQueryParam =
  | IntegrationRegistrationFeatureStatus
  | IntegrationRegistrationFeatureStatus[];

export type GetIntegrationRegistrationsQueryParams = {
  integrationId?: number;
  partnerId?: number | number[];
  workspaceId?: number | number[];
  includeArchived?: boolean;
  pageNumber?: number;
  pageSize?: number;
  status?: StatusQueryParam;
  feature?: number | number[];
  orderBy?: 'lastSync' | 'workspaceName';
  ascending?: boolean;
  workspaceType?: string | string[];
};

export type IntegrationActivityLog<T> = ActivityLog & {
  requestJson: T;
  integrationId: number;
  featureId?: number;
  flowContainerId?: number;
  serviceId?: number;
  featureServiceId?: number;
  integrationScheduleId?: number;
  newValues?: string;
  oldValues?: string;
};

export type IntegrationRegistrationFeatureStatus =
  | 'unknown'
  | 'onboarding'
  | 'maintenance'
  | 'active';

export type AppRegistration = {
  id: number;
  appId: number;
  appName: string;
  partnerId: number;
  partnerName: string;
  partnerUrlPart: string;
  configuration: Record<string, unknown>;
};
export type AppRegistrationPathParams = { $appRegistrationId: number };

export type AppSummary = {
  id: number;
  name: string;
  partnerId: number;
  description: string | null;
};
export type AppPathParams = { $appId: number };
export type App = AppSummary & {
  connectionSchema: Record<string, unknown>;
};

export type AppTokenPathParams = AppPathParams & {
  $tokenHash: string;
};
export type AppToken = Record<string, unknown> & {
  type: string | null;
  title: string | null;
  status: number | null;
  string: string | null;
  instance: string | null;
};

export type IntegrationPathParams = { $integrationId: number };
export type PostIntegrationSchedulesBody = Omit<
  IntegrationSchedule,
  'id' | 'integrationId'
>;
export type PutIntegrationScheduleBody = Omit<
  IntegrationSchedule,
  'id' | 'integrationId'
>;

export type IntegrationRegistrationPathParams = {
  $registrationId: number | undefined;
};
export type IntegrationRegistrationProjectPathParams =
  IntegrationRegistrationPathParams & { $registrationProjectId: number };

export enum IntegrationStatusEnum {
  underConstruction = 1,
  active = 2,
  deprecated = 3,
  archived = 4,
}

export type IntegrationStatus =
  | 'underConstruction'
  | 'active'
  | 'deprecated'
  | 'archived';

export type IntegrationConfigurationSchema = {
  type: 'object';
  required?: string[];
  properties: IntegrationConfigurationSchemaProperties;
};

export type IntegrationConfigurationSchemaProperties = Record<
  string,
  IntegrationConfigurationSchemaProperty
>;

export type IntegrationConfigurationSchemaProperty = SchemaProperty & {
  $id: string;
  type: ConfigPropertyTypes;
  items?: SchemaProperty & {
    type: ConfigPropertyTypes;
  };
};

export type IntegrationConfiguration = {
  id: number;
  integrationId: number;
  configurationSchema: IntegrationConfigurationSchema;
  configurationHierarchy: XTreeDataNode[];
};

export type PostIntegrationsBody = {
  name?: string;
  appIds: number[];
  partnerId?: number;
  description?: string;
  type: IntegrationType;
  configurationSchema?: IntegrationConfigurationSchema;
  accountId: number;
};

export type IntegrationType = 'v1' | 'v2' | 'customPrivate' | 'project';

export type Integration = {
  id: number;
  name: string;
  description: string;
  partnerId?: number;
  status: IntegrationStatus;
  type: IntegrationType;
  apps: AppSummary[];
  features: IntegrationFeatureSummary[];
  configurationSchema?: IntegrationConfigurationSchema;
  accountId: number;
};
export type IntegrationSummary = Omit<Integration, 'apps'>;

export type ConfigurationValueMap = {
  sourcePropertyId: string;
  sourceType: string;
  targetPropertyId: string;
};

export type IntegrationFlow = {
  id: number;
  containerId: number;
  integrationId: number;
  name: string;
  active: boolean;
  isTemplate: boolean;
  configurationValueMap?: ConfigurationValueMap[] | null;
  isCallable: boolean;
};

export type IntegrationService = {
  id: number;
  integrationId: number;
  serviceId: number;
  serviceName: string | null;
  configurationJson: string | null;
  active: boolean;
  description: string | null;
  isTemplate: boolean;
};

export type IntegrationRegistrationFeature = {
  id: number;
  integrationRegistrationId: number;
  integrationFeatureId: number;
  status: IntegrationRegistrationFeatureStatus;
};

export type IntegrationRegistrationApp = {
  id: number;
  appId: number;
  appName: string;
};

type IntegrationRegistrationOptionalFlow = {
  id: number;
  integrationRegistrationId: number;
  integrationFeatureFlowId: number;
};

export type IntegrationRegistrationFlow = {
  id: number;
  integrationRegistrationId: number;
  integrationFeatureFlowId: number;
  isActivated: boolean;
};

export type IntegrationRegistrationStatus =
  | 'onBoarding'
  | 'active'
  | 'inactive'
  | 'archived';

type IntegrationRegistrationWorkspace = {
  id: number;
  managedBy: number | null;
  name: string;
  workspaceType: WorkspaceType;
};

export type IntegrationRegistrationSyncStatus =
  | 'failed'
  | 'finished'
  | 'processing'
  | 'queued'
  | 'skipped';

export type GetIntegrationRegistrationSyncResponse =
  PaginatedResponse<IntegrationRegistrationSync>;

export type IntegrationRegistrationSync = {
  id: number;
  workspaceId: number;
  integrationId: number;
  integrationRegistrationId: number;
  status: IntegrationRegistrationSyncStatus;
  message: string | null;
  enqueuedOn: string;
  finishedOn: string | null;
  initiatingUsername: string | null;
  flowsSyncFinishedOn: string | null;
  flowsSyncStartedOn: string | null;
  systemsSyncStartedOn: string | null;
  systemsSyncFinishedOn: string | null;
  servicesSyncStartedOn: string | null;
  servicesSyncFinishedOn: string | null;
  schedulesSyncStartedOn: string | null;
  schedulesSyncFinishedOn: string | null;
  cleanupStartedOn: string | null;
  cleanupFinishedOn: string | null;
};

export type IntegrationRegistration = {
  id: number;
  integrationId: number;
  status: IntegrationRegistrationStatus;
  features: IntegrationRegistrationFeature[];
  appRegistrations: IntegrationRegistrationApp[];
  configurationJson: Record<string, unknown> | null;
  registeredOptionalFlows: IntegrationRegistrationOptionalFlow[];
  workspace: IntegrationRegistrationWorkspace;
};

export type IntegrationRegistrationSummary = Pick<
  IntegrationRegistration,
  | 'id'
  | 'workspace'
  | 'integrationId'
  | 'status'
  | 'features'
  | 'appRegistrations'
> & {
  latestSync: null | IntegrationRegistrationSync;
};

export type IntegrationScheduleSequence = {
  id: number;
  integrationFeatureScheduleId: number;
  integrationFeatureServiceId?: number;
  integrationFeatureFlowId?: number;
  sequence: number;
  continueWithoutFinish?: boolean;
};

type IntegrationScheduleIntervalType =
  | 'once'
  | 'minute'
  | 'hour'
  | 'day'
  | 'month';

export type IntegrationSchedule = {
  id: number;
  integrationId: number;
  name: string;
  interval: number;
  intervalType: IntegrationScheduleIntervalType;
  sequences: IntegrationScheduleSequence[];
  active: boolean;
};

export type Service = { id: number; name?: string; appId: number };

export type IntegrationInstruction = {
  content: string;
  createdOn: string;
  id: number;
  integrationId: number;
  modifiedOn?: string | null;
};

export type IntegrationDataObject = {
  id: number;
  integrationId: number;
  appId: number;
  dataObjectId: number;
  displayJson: Record<string, string>;
};

export type IntegrationRegistrationProject = {
  id: number;
  integrationRegistrationId: number;
  appRegistrationId: number;
  dataObjectId: number;
  projectId: string;
  appId: number;
  displayJson: Record<string, string>;
};

export type AppRegistrationAuthIdentity = {
  id: number;
  appRegistrationId: number;
  username: string;
  createdBy: string;
  createdOn: string;
};

export type PostIntegrationRegistrationFeaturesBody = {
  integrationFeatureId: number;
  status: IntegrationRegistrationStatus;
};
export type PostIntegrationRegistrationsBody = {
  workspaceId: number;
  integrationId: number;
  features: PostIntegrationRegistrationFeaturesBody[];
  configurationJson?: Record<string, unknown> | null;
  registeredOptionalFlows: number[];
};
