import dayjs from 'dayjs';
import lodash from 'lodash';
import numeral from 'numeral';
import * as uuid from 'uuid';

const h1Styles = `
  background: white;
  padding: 16px;
  border: 3px solid #0065a1;
  font-size: 24px;
  font-weight: bold;
  color: #0065a1;
  display: block;
  text-align: center;
  margin: 16px 0;
`;
const linkStyles = `
  color: #0065a1;
`;
const warningStyles = `
  background: wheat;
  padding: 8px;
  border: 1px solid #FFA500;
  font-size: 12px;
  font-weight: bold;
  color: firebrick;
  display: block;
  text-align: center;
`;

console.info(
  `%c⚠️ Warning ⚠️
Do not copy-paste code from untrusted sources.
It may compromise your data and your account.%c
%cWelcome to the App Xchange console!%c
Here are the libraries available while writing flows:
- _ (lodash): %chttps://lodash.com/docs/4.17.15%c
- numeral: %chttp://numeraljs.com/%c
- dayjs: %chttps://day.js.org/%c
- uuid: %chttps://www.npmjs.com/package/uuid%c

For more help, visit:
- help docs: %chttps://docs.appxchange.trimble.com/home%c
- js reference: %chttps://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference%c

Happy coding! 🚀`,
  warningStyles,
  '',
  h1Styles,
  '',
  linkStyles,
  '',
  linkStyles,
  '',
  linkStyles,
  '',
  linkStyles,
  '',
  linkStyles,
  '',
  linkStyles,
  '',
);

// expose the libraries flow authors have
// access to for testing in the console
//
// lodash & numeral automatically does this,
// but wanted to include it here for clarity
window._ = lodash;
window.numeral = numeral;
window.dayjs = dayjs;
window.uuid = uuid;

declare global {
  interface Window {
    dayjs: any;
    uuid: any;
  }
}
