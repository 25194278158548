export const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export const URLS = {
  account: `${APP_BASE_URL}/api/account`,
  app: `${APP_BASE_URL}/api/portal`,
  appBuilder: `${APP_BASE_URL}/api/app-builder`,
  explorer: `${APP_BASE_URL}/appnetwork/explorer`,
  integration: `${APP_BASE_URL}/api/integration`,
  login: `${APP_BASE_URL}/api/portal/Token`,
  partner: `${APP_BASE_URL}/api`,
  partnerPortal: process.env.REACT_APP_PARTNER_PORTAL_URL,
  signalr: process.env.REACT_APP_SIGNALR_URL,
  flows: `${APP_BASE_URL}/api/workflows`,
  schedules: `${process.env.REACT_APP_API_URL}/api/servicesequenceschedules`,
  analytics: `${APP_BASE_URL}/api/analytics`,
};
