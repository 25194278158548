import * as R from 'ramda';
import { QueryClient } from 'react-query';
import { UseAxiosOptions } from '../types';
import { getDerivedUrl } from './getUrl';

type QueryKey = (string | number | Record<string, unknown>)[];

const appendOptions = R.curry(
  (axiosOptions: UseAxiosOptions<any, any, any>, key: QueryKey) =>
    R.compose(
      R.ifElse(R.isEmpty, R.always(key), R.append(R.__, key)),
      R.pick(['params', 'data']),
    )(axiosOptions),
);

export function getQueryKey<TRequestBody, TPathParams, TQueryParams>(
  axiosOptions: UseAxiosOptions<TRequestBody, TPathParams, TQueryParams>,
  nthAncestor = 0,
): QueryKey {
  return R.compose(
    R.dropLast(nthAncestor),
    appendOptions(axiosOptions),
    R.filter(Boolean),
    R.split('/'),
    R.replace(/^.+com\/api/, ''),
    getDerivedUrl,
  )(axiosOptions);
}

export function invalidateQueryKey<TRequestBody, TPathParams, TQueryParams>(
  queryClient: QueryClient,
  axiosOptions: UseAxiosOptions<TRequestBody, TPathParams, TQueryParams>,
  nthAncestor = 0,
): void {
  const key = getQueryKey(axiosOptions);
  const invalidateKey = R.dropLast(nthAncestor, key);

  queryClient.invalidateQueries(invalidateKey);
}
