import { PaginatedResponse } from '@x/types';
import { URLS } from 'config';
import { ApiMutationConfig, ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { GetUsersParams, User, UserRole } from '../types';

type GetUsersConfig = ApiQueryConfig<
  PaginatedResponse<User>,
  unknown,
  GetUsersParams
>;
type PostUserBody = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phoneNumber: string;
  workspaceId: number;
  roles: UserRole[];
};

type PostUserConfig = ApiMutationConfig<PostUserBody, unknown, User>;

const url = `${URLS.account}/v1/users`;

export const getUsers = createApiEndpoint<GetUsersConfig>({
  url,
  method: 'GET',
  cacheTime: 1000 * 60 * 60, // this rarely changes, so only refetch after an hour
  staleTime: 1000 * 60 * 60,
});
export const postUsers = createApiEndpoint<PostUserConfig>({
  url,
  method: 'POST',
});
