import { URLS } from 'config';
import { ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { GetRolesWorkspaceResponse, WorkspaceType } from '../types';

type GetRolesWorkspacePathParams = {
  $workspaceType: WorkspaceType;
};

type GetRolesWorkspaceConfig = ApiQueryConfig<
  GetRolesWorkspaceResponse,
  GetRolesWorkspacePathParams
>;

const url = `${URLS.account}/v1/roles/$workspaceType`;

export const getRolesByWorkspaceType =
  createApiEndpoint<GetRolesWorkspaceConfig>({
    url,
    method: 'GET',
  });
