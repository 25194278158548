import * as R from 'ramda';
import { UseQueryResult } from 'react-query';

const getErrorStatus: (query: UseQueryResult) => number | undefined = R.path([
  'error',
  'response',
  'status',
]);

export const isClientError: (query: Partial<UseQueryResult>) => boolean =
  R.pipe(getErrorStatus, R.allPass([R.gte(R.__, 400), R.lt(R.__, 500)]));
