import { URLS } from 'config';
import { ApiMutationConfig, ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { GetInvitesResponse, Invite, PostInvitesBody } from '../types';

type GetInvitesQueryParams = {
  partnerId?: number;
  pageNumber?: number;
  pageSize?: number;
  search?: string;
  orderBy?: string;
  ascending?: boolean;
  accountId?: number;
  email?: string;
  showAll?: boolean;
};

type GetInvitesConfig = ApiQueryConfig<
  GetInvitesResponse,
  unknown,
  GetInvitesQueryParams
>;

type PostInvitesConfig = ApiMutationConfig<PostInvitesBody, unknown, Invite>;

const url = `${URLS.account}/v1/invites`;

export const getInvites = createApiEndpoint<GetInvitesConfig>({
  url,
  method: 'GET',
});

export const postInvites = createApiEndpoint<PostInvitesConfig>({
  url,
  method: 'POST',
});
