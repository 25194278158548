import { AuthorizedWorkspace } from '@workspaces';
import { xTheme } from '@x/styles';
import { Layout } from 'antd';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import { AuthStatus } from 'features/user/components/AuthStatus';
import { LoginError } from 'features/user/components/LoginError';
import { Logout } from 'features/user/components/Logout';
import { VerifyInvite } from 'features/user/features/invites';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from 'react-oidc-context';
import { Route, Switch } from 'react-router';

export function App(): React.JSX.Element {
  const auth = useAuth();

  useEffect(() => {
    return auth.events.addSilentRenewError(() => {
      auth.signinRedirect();
    });
  }, [auth.events, auth]);

  if (auth.isLoading) {
    return <AuthStatus message="Finding your profile..." />;
  }

  const isNonProd = process.env.REACT_APP_ENVIRONMENT !== 'production';

  function faviconUrl(size: number) {
    return `/favicon-${size}x${size}${isNonProd ? '-dev' : ''}.png`;
  }

  return (
    <>
      {isNonProd && (
        <div
          style={{
            background: xTheme.colors.brandSecondary,
            position: 'fixed',
            zIndex: 1000,
            margin: '0 auto',
            left: 0,
            right: 0,
            width: 'fit-content',
            padding: '4px 12px',
            color: 'white',
            borderRadius: '0 0 4px 4px',
            fontSize: '.8em',
          }}
        >
          Non Prod
        </div>
      )}
      <Helmet>
        <title>App Xchange</title>
        <link rel="icon" type="image/png" sizes="32x32" href={faviconUrl(32)} />
        <link rel="icon" type="image/png" sizes="16x16" href={faviconUrl(16)} />
      </Helmet>

      <Switch>
        <Route path="/invite" exact>
          <VerifyInvite />
        </Route>
        <Route path="/login-error">
          <LoginError
            message={auth.error ? auth.error.message : 'Authentication Error'}
          />
        </Route>
        <Route path="/logout">
          <Logout />
        </Route>
        <AuthenticatedRoute>
          <Layout data-testid="loggedIn" style={{ minHeight: '100vh' }}>
            <React.Suspense fallback="">
              <AuthorizedWorkspace />
            </React.Suspense>
          </Layout>
        </AuthenticatedRoute>
      </Switch>
    </>
  );
}
