import { saveAs } from 'file-saver';
import { axios } from 'lib/axios';
import * as R from 'ramda';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';
import { MUTATION_OPTION_KEYS } from '../config';
import { ApiMutationConfig, UseAxiosOptions } from '../types';
import { getAxiosOptions, invalidateQueryKey } from '../utils';

/**
 * @deprecated use the version in "@x/api"
 */
export function useAxiosMutation<
  TRequestBody,
  TPathParams,
  TResponseData,
  TQueryParams,
>({
  skipInvalidate,
  invalidateNthAncestor = 0,
  ...config
}: ApiMutationConfig<
  TRequestBody,
  TPathParams,
  TResponseData,
  TQueryParams
>): UseMutationResult<
  TResponseData,
  unknown,
  UseAxiosOptions<TRequestBody, TPathParams, TQueryParams>
> {
  const mutationOptions = R.pick(MUTATION_OPTION_KEYS, config);
  const queryClient = useQueryClient();

  // using "any" type here because axios doesn't let us specify the correct type
  return useMutation<
    any,
    unknown,
    UseAxiosOptions<TRequestBody, TPathParams, TQueryParams>
  >(
    async (
      mutateParams: UseAxiosOptions<TRequestBody, TPathParams, TQueryParams>,
    ) => {
      const configWithOverrides = R.mergeRight(config, mutateParams);
      const axiosOptions = getAxiosOptions(configWithOverrides);
      const res = await axios(axiosOptions);

      if (!skipInvalidate) {
        invalidateQueryKey(
          queryClient,
          R.omit(['data', 'params'], axiosOptions),
          invalidateNthAncestor,
        );
      }

      return res;
    },
    mutationOptions,
  );
}

function saveJson(data: unknown, filename = 'file') {
  const json = JSON.stringify(data, null, 2);

  saveAs(new Blob([json], { type: 'application/json' }), `${filename}.json`);
}

export function useAxiosDownload<
  TRequestBody,
  TPathParams,
  TResponseData,
  TQueryParams,
>(
  config: ApiMutationConfig<
    TRequestBody,
    TPathParams,
    TResponseData,
    TQueryParams
  >,
  filename?: string,
): UseMutationResult<
  TResponseData,
  unknown,
  UseAxiosOptions<TRequestBody, TPathParams, TQueryParams>
> {
  return useAxiosMutation({
    skipInvalidate: true,
    ...config,
    onSuccess: (response) => {
      if (response) saveJson(response, filename);
    },
  });
}
