import { URLS } from 'config';
import { ApiMutationConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { InvitePathParams } from '../types';

type ResendInviteConfig = ApiMutationConfig<unknown, InvitePathParams>;

const url = `${URLS.account}/v1/invites/$inviteId/resend`;

export const putInviteResend = createApiEndpoint<ResendInviteConfig>({
  url,
  method: 'PUT',
});
