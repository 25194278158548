import { AppNetworkOperator } from '@x/types';
import { contentMediaTypes } from 'features/schemaBuilder';

export type ConnectorConfig = {
  PartnerName: string;
  AppName: string;
  UrlPart: string;
  Description?: string;
  CdxEnabled?: boolean;
  SettingsSchema?: SettingsSchema;
  RegistrationSettingsSchema?: SettingsSchema;
  OpenApiSpecs: OpenApiSpecReferences;
  Modules: Module[];
};

export type SettingsSchema = {
  [Key: string]: {
    Title: string;
    Description?: string;
    Format?: string;
    Type: string;
    IsRequired: boolean;
  };
};

export type OpenApiSpecReferences = {
  [Key: string]: {
    Name?: string;
    Description?: string;
    Location: string;
  };
};

export type Module = {
  Name: string;
  UrlPart: string;
  Version?: number;
  Description: string;
  OpenApiSpec: string;
  PaginationSchemes?: PaginationSchemes;
  DataObjects?: ConnectorResource[];
  FileObjects?: ConnectorFile[];
};

export type PaginationSchemes = Record<string, PaginationScheme>;

export type PaginationScheme = {
  Type: 'Paged' | 'Offset' | 'NextUrl';
  PageNumberParameter?: PaginationSchemeParameter;
  PageSizeParameter?: PaginationSchemeParameter;
  PageTotalParameter?: PaginationSchemeParameter;
  HasMoreParameter?: PaginationSchemeParameter;
  TotalParameter?: PageTotalParameter;
  OffsetParameter?: PaginationSchemeParameter;
  LimitParameter?: PaginationSchemeParameter;
  ReadUntilEmpty?: boolean;
};

export type PaginationSchemeParameter = {
  RequestIn?: string;
  RequestName?: string;
  RequestPath?: string;
  ResponseIn?: string;
  ResponseName?: string;
  ResponsePath?: string;
};

export type PageTotalParameter = Omit<
  PaginationSchemeParameter,
  'RequestIn' | 'RequestName'
>;

export type ConnectorResource = {
  Name: string;
  UrlPart: string;
  Description?: string;
  CachingScheme?: CachingScheme;
  GetSingle?: DetailEndpoint;
  GetList?: ListEndpoint;
  ScopedByDataObject?: ScopedByDataObject;
  RequestParameterMaps?: RequestParameterMap[];
  Actions?: Action[];
  Keys: string[];
};

export type CachingScheme = {
  Type: CacheType;
  ModifiedSinceParameterMap?: ModifiedSinceParameterMap;
  RequestParameterMaps?: RequestParameterMap[];
};

export type ConnectorFile = {
  Name: string;
  UrlPart: string;
  Description?: string;
  ApiPath: string;
  Operation: Operations;
  SuccessfulCode: number;
  ContentType: typeof contentMediaTypes;
  Keys: string[];
  RequestParameterMaps?: RequestParameterMap[];
};

export type CacheType = 'List' | 'ListDetail' | 'Single' | 'None';

export type ModifiedSinceParameterMap = {
  Name: string;
  Location: string;
  DateTimeFormat: string;
};

export type DetailEndpoint = {
  ApiPath?: string;
  DataPath?: string;
  Operation?: Operations;
  RequestParameterMaps?: RequestParameterMap[];
};

export type Operations = keyof typeof OperationType;

export enum OperationType {
  get,
  post,
  put,
  delete,
  options,
  head,
  patch,
  trace,
}

export type RequestFilter = {
  Name: string;
  Location: string;
};

export type ListEndpoint = DetailEndpoint & {
  Pagination?: string;
  RequestFilters?: RequestFilter[];
};

export type ScopedByDataObject = {
  UrlPart?: string;
  Filters?: ScopedByFilter[];
  PropertyMaps?: {
    FromProperty: string;
    ToProperty: string;
    DoesToPropertyExist: boolean;
  }[];
  ParameterMaps?: ParameterMap[];
};

export type ScopedByFilter = {
  Path: string;
  Operator: AppNetworkOperator;
  Value: { Source: string; Target: string; Type: 'String' | 'Bool' | 'Number' };
};

export type ParameterMap = {
  IsARelation?: boolean;
  Relationpath?: string;
  PropertyPath: string;
  Parameter: string;
};

export type RequestParameterMap = {
  Name: string;
  Location: string;
  Value: { Source: string; Target: string };
};

export type RequestParameterMapSource =
  | 'Static'
  | 'Settings'
  | 'RegistrationSettings'
  | 'ScopedByDataObject'
  | 'DetailResponseItem'
  | 'ResponseBody'
  | 'ResponseHeader';

export type Action = {
  ApiPath: string;
  CacheChanges: CacheChanges;
  Description?: string;
  Name: string;
  Operation: Operations;
  SuccessfulCode: number;
  UrlPart: string;
  RequestParameterMaps?: RequestParameterMap[];
};

export type CacheChanges = {
  Disable?: boolean;
  IsDelete?: boolean;
  DataPath?: string;
  RequestParameterMaps?: RequestParameterMap[];
  GetSingle?: boolean;
  GetList?: boolean;
};
