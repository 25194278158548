import { deriveProp } from '@x/utils';
import * as R from 'ramda';
import { AXIOS_OPTION_KEYS } from '../config';
import { UseAxiosOptions } from '../types';
import { getDerivedUrl } from './getUrl';

export function getAxiosOptions(
  config: UseAxiosOptions<any, any, any>,
): UseAxiosOptions<any, any, any> {
  return R.compose(
    R.pick(AXIOS_OPTION_KEYS),
    deriveProp('url', getDerivedUrl),
  )(config);
}
