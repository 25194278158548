import { Workspace } from 'api/types';
import * as R from 'ramda';

export const getWorkspacesFromLocalStorage = R.compose(
  JSON.parse,
  R.defaultTo('[]'),
  (key: string) => localStorage.getItem(key),
);

export const saveWorkspacesToLocalStorage = R.curry(
  (key: string, value: Workspace[]) =>
    localStorage.setItem(key, JSON.stringify(value)),
);
