import { CheckCircleTwoTone } from '@ant-design/icons';
import { xTheme } from '@x/styles';
import { Button, Card, Space, Typography } from 'antd';
import { UnauthorizedPage } from 'components/Layout/UnauthorizedPage';
import React from 'react';
import { useAuth } from 'react-oidc-context';

const { colors } = xTheme;

export function Logout(): React.JSX.Element {
  const auth = useAuth();

  return (
    <UnauthorizedPage top={'35%'}>
      <Card style={{ textAlign: 'center' }}>
        <Space direction="vertical">
          <CheckCircleTwoTone
            twoToneColor={colors.success}
            style={{ fontSize: '32px' }}
          />
          <Typography.Text type="secondary">
            You have been logged out successfully
          </Typography.Text>
          <Space>
            <Button
              type="primary"
              onClick={() =>
                auth.signinRedirect({ extraQueryParams: { originalUrl: '/' } })
              }
            >
              Login
            </Button>
          </Space>
        </Space>
      </Card>
    </UnauthorizedPage>
  );
}
