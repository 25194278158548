import { useQueryString } from '@x/hooks';
import { Button, Card, Typography } from 'antd';
import { InviteShort } from 'api/types';
import { VSpace } from 'components/VSpace';
import React from 'react';
import { useAuth } from 'react-oidc-context';

export function AcceptInvite({
  invite,
}: {
  invite: InviteShort;
}): React.JSX.Element {
  const auth = useAuth();
  const { token } = useQueryString();

  return (
    <Card
      style={{
        textAlign: 'center',
      }}
    >
      <VSpace>
        <Typography.Title level={4}>
          {`Welcome! ${invite.email}`}
        </Typography.Title>
        <Typography.Text type="secondary">
          Join the company account for:
        </Typography.Text>
        <Typography.Title
          level={2}
        >{`${invite.account.name}`}</Typography.Title>
        <Typography.Text>
          Clicking Join will take you to the Trimble login page. Please login or
          create a Trimble ID using the email address above.
        </Typography.Text>
        <Button
          block
          type="primary"
          onClick={() =>
            auth.signinRedirect({
              extraQueryParams: { login_hint: invite.email },
              extraTokenParams: { invite_token: token },
            })
          }
        >
          Join
        </Button>
      </VSpace>
    </Card>
  );
}
