import { URLS } from 'config';
import { ApiMutationConfig, ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { Account, UpsertAccountRequest } from '../types';

type AccountPathParams = { $accountId: number };
type GetAccountConfig = ApiQueryConfig<Account, AccountPathParams>;
type PutAccountConfig = ApiMutationConfig<
  UpsertAccountRequest,
  AccountPathParams
>;

const url = `${URLS.account}/v1/accounts/$accountId`;

export const getAccount = createApiEndpoint<GetAccountConfig>({
  method: 'GET',
  url,
});

export const putAccount = createApiEndpoint<PutAccountConfig>({
  method: 'PUT',
  url,
});
