import * as R from 'ramda';

export const isInvalidPathParam: (
  val: string | number | null | undefined,
) => boolean = R.anyPass([R.isNil, Number.isNaN, R.equals('')]);

type IsLoading = { isLoading: boolean }[];
type IsFetching = { isFetching: boolean }[];
type IsError = { isError: boolean }[];

export const anyLoading: (data: IsLoading) => boolean = R.any(
  R.prop('isLoading'),
);

export const anyFetching: (data: IsFetching) => boolean = R.any(
  R.prop('isFetching'),
);

export const anyError: (data: IsError) => boolean = R.any(R.prop('isError'));
