import { useMemo } from 'react';
import { AccessType, AuthenticatedUser } from '../types';
import { useUserToken } from './useUserToken';

export function useAuthenticatedUser(): AuthenticatedUser {
  const token = useUserToken();

  if (!token) {
    throw new Error('Expected authenticated user to exist but none were found');
  }

  return useMemo(
    () => ({
      hasPermission: (permission: string) =>
        token['http://ryvit.com/permissions'].includes(permission) ?? false,
      isPrincipal: token.accessType === AccessType.Principal,
      isPartner: token.accessType === AccessType.Partner,
      isCustomer: token.accessType === AccessType.Customer,
      id: token.sub,
      roles: token.roles,
      username: token.username,
    }),
    [token],
  );
}
