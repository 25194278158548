/* eslint-disable no-redeclare */ // TODO clear
import { Method } from 'axios';
import * as R from 'ramda';
import { ApiMutationConfig, ApiQueryConfig } from '../types';

type AnyQueryConfig = ApiQueryConfig<any>;
type AnyMutationConfig = ApiMutationConfig<any, any, any, any>;
type DefaultConfig<TConfig> = Partial<Omit<TConfig, 'data'>> & {
  url: string;
  method: Method;
};

export function createApiEndpoint<TConfig extends AnyQueryConfig>(
  defaultConfig: DefaultConfig<TConfig>,
): (
  ...args: Partial<TConfig> extends TConfig ? [TConfig?] : [TConfig]
) => TConfig;

export function createApiEndpoint<TConfig extends AnyMutationConfig>(
  defaultConfig: DefaultConfig<TConfig>,
): (config?: Partial<TConfig>) => TConfig;

export function createApiEndpoint<
  TConfig extends AnyQueryConfig | AnyMutationConfig,
>({
  url,
  method,
  ...defaultConfig
}: DefaultConfig<TConfig>):
  | ((config?: TConfig) => TConfig)
  | ((config: TConfig) => TConfig) {
  return (config: TConfig): TConfig => {
    return R.compose(
      R.mergeDeepRight(defaultConfig), // any other defaults given will be overwritten if on the config
      R.mergeDeepRight(config), // if url and method are on the config, they will be overwritten
    )({ url, method }); // always use provided url and mthod
  };
}
