export const AXIOS_OPTION_KEYS = [
  'url',
  'method',
  'baseURL',
  'transformRequest',
  'transformResponse',
  'headers',
  'params',
  'paramsSerializer',
  'data',
  'timeout',
  'withCredentials',
  'adapter',
  'auth',
  'responseType',
  'responseEncoding',
  'xsrfCookieName',
  'xsrfHeaderName',
  'onUploadProgress',
  'onDownloadProgress',
  'maxContentLength',
  'maxBodyLength',
  'validateStatus',
  'maxRedirects',
  'socketPath',
  'httpAgent',
  'httpsAgent',
  'proxy',
  'cancelToken',
  'decompress',
];

export const INVALID_URL =
  '__UNABLE_TO_DERIVE_URL_DUE_TO_MISSING_PATH_PARAMS__';

export const MUTATION_OPTION_KEYS = [
  'mutationKey',
  'onError',
  'onMutate',
  'onSettled',
  'onSuccess',
  'retry',
  'retryDelay',
  'useErrorBoundary',
  'meta',
];

export const QUERY_OPTION_KEYS = [
  'queryKey',
  'cacheTime',
  'enabled',
  'initialData',
  'initialDataUpdatedAt',
  'isDataEqual',
  'keepPreviousData',
  'meta',
  'notifyOnChangeProps',
  'notifyOnChangePropsExclusions',
  'onError',
  'onSettled',
  'onSuccess',
  'queryKeyHashFn',
  'refetchInterval',
  'refetchIntervalInBackground',
  'refetchOnMount',
  'refetchOnReconnect',
  'refetchOnWindowFocus',
  'retry',
  'retryOnMount',
  'retryDelay',
  'select',
  'staleTime',
  'structuralSharing',
  'suspense',
  'useErrorBoundary',
];

export const HIDDEN_VALUE = '--hidden-value--';
export const HIDDEN_VALUE_VIEW = '************************************';
