import { UserRole } from 'api/types';

export interface Auth {
  access_token: string;
  expires_in: number;
  expiry: number;
}

export interface Credentials {
  email: string;
  password: string;
}

export interface WorkspaceTokenRequest {
  WorkspaceId: number;
}

export interface PasswordResetRequest {
  token: string;
  password: string;
  userId: string;
}

export enum AccessType {
  Principal = 'principal',
  Partner = 'partner',
  Customer = 'customer',
}

export type UserToken = {
  accessType: AccessType;
  'http://ryvit.com/permissions': string[];
  roles: UserRole[];
  username: string;
  sub: string;
};

export type AuthenticatedUser = {
  hasPermission: (permission: string) => boolean;
  isPrincipal: boolean;
  isPartner: boolean;
  isCustomer: boolean;
  roles: UserRole[];
  id: string;
  username: string;
};

export type UserFormData = {
  emails?: string;
  email?: string;
  roles: UserRole[];
  additionalPermissions: AdditionalPermission[];
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
};

export type InviteFormData = {
  emails: string;
  accountAssignment: {
    roles: UserRole[];
    additionalPermissions: AdditionalPermission[];
    accountId: number;
  };
};

export type RolesFormData = {
  roles: UserRole[];
  additionalPermssions: AdditionalPermission[];
  [key: string]: any;
};

export enum AdditionalPermission {
  PlatformAdmin = UserRole.PlatformAdmin,
  FlowAuthor = UserRole.FlowAuthor,
  IntegrationAuthor = UserRole.IntegrationAuthor,
  AppBuilderAuthor = UserRole.AppBuilderAuthor,
  ConnectorAuthor = UserRole.ConnectorAuthor,
}
