import { BasePaginationParams } from '@x/types';
import { FlowTriggerEventType } from './FlowDefinitionTypes';

export type FlowContainer = {
  id: number;
  name: string;
  code: string | null;
  description: string | null;
  draftDefinitionId: number | null;
  lastPublishedDateTime?: string | null;
  lastDraftDateTime?: string;
  isCallable: boolean;
  archivedDate?: string | null;
  isShareable?: boolean;
  isTemplate?: boolean;
  isRemediation?: boolean;
  accountId: number;
  mainDefinitionId: number | null;
  previousMainDefinitionId: number | null;
  stagedDefinitionId: number | null;
  sourceTemplateContainerId?: number | null;
  containsSensitiveData: boolean;
};

export type FlowCallingContainer = {
  id: number;
  subscriberId: number;
  createdDate: string;
  modifiedDate: string;
  archivedDate: string | null;
  isActive: boolean;
  ryvitFlowContainerId: number;
};

export type FlowRunOutcome = {
  outcomeMessage: string;
  outcomeDetail?: string;
};

export type WorkspaceByTask = {
  id: string;
  name: string;
  count: number;
};

export type WorkspaceFlowBacklogResponse = {
  timestamp: number;
  backlogs: WorkspaceFlowBacklog[];
};

export type WorkspaceFlowBacklog = {
  id: string;
  name: string;
  count: number;
};

export type FlowInstancePathParams = {
  $workspaceId: number;
  $registrationId: number;
  $instanceId: string;
};

export type FlowRun = {
  instanceId: string;
  definitionId: number;
  definitionName: string;
  startedOn?: string | null;
  finishedOn?: string | null;
  lastModifiedOn?: string | null;
  status: FlowRunStatus;
  isSimulated: boolean;
  id: number;
  totalActions: number;
  totalCompletedTasks: number;
  totalFailedActions: number;
  totalSuccessfulActions: number;
  totalPendingRetryActions: number;
  totalTasks: number;
  hasErrors: boolean;
  terminatedEarly: boolean;
  outcomeMessage?: string;
  registrationId?: number;
  initiatingInstanceId?: string;
  engineVersion: number | null;
};

export type FlowRunSummary = {
  definitionId: number;
  finishedOn: string | null;
  id: number;
  isPruned: boolean;
  modifiedOn: string | null;
  originId: number | null;
  outcomeMessage?: string | null;
  registrationId: number;
  status: FlowRunStatus;
  startedOn?: string | null;
  totalTasks: number;
  totalCompletedTasks: number;
  totalActions: number;
  totalSuccessfulActions: number;
  totalFailedActions: number;
  totalPendingRetryActions: number;
  workflowInstanceId: string;
  workspaceId: number;
};

export enum FlowRunStatuses {
  queued = 'queued',
  running = 'running',
  suspended = 'suspended',
  successful = 'successful',
  exception = 'exception',
  failed = 'failed',
  canceled = 'canceled',
}

export type FlowRunStatus = keyof typeof FlowRunStatuses;

export type Flow = {
  containerId: number;
  registrationId: number;
  subscriberId: number;
  name: string;
  code: string | null;
  description: string | null;
  draftDefinitionId: number | null;
  lastPublishedDateTime: string | null;
  lastDraftDateTime: string | null;
  isActive: boolean;
  isCallable: boolean;
  isShareable: boolean;
  isTemplate: boolean;
  isRemediation: boolean;
  archivedDate: string | null;
  callingFlows?: Flow[];
  isInvalidConfig: boolean;
  configurationJson?: Record<string, any> | null;
  integrationFeatureResourceId?: number;
  mainDefinitionId: number | null;
  stagedDefinitionId: number | null;
  previousMainDefinitionId: number | null;
  useStaged: boolean;
  sourceTemplateContainerId?: number;
};

export enum FlowEventType {
  CacheChanged = 100,
  ChildFlowStarted = 241,
  ChildFlowFinished = 242,
  ActionClosed = 994,
  UserTaskResolved = 300,
  FlowFinished = 1234,
  FlowActionClosed = 400,
  OnDemand = 500,
  WorkRequestBatchReady = 600,
}

export type FlowEvent = {
  SubscriberId: number;
  Type: FlowEventType;
  EventInfo: {
    SubscriberId: number;
    ActionInstanceId?: string;
    ActionPath?: string;
    ActionStatus?: number;
    CacheId?: string;
    CacheChangeType?: number;
    CacheUrl?: string;
    Context?: Record<string, any>;
    DataObjectPath?: string;
    EventId?: number;
    WorkItemCollectionKey?: string;
    WorkItemType?: number;
    WorkRequestDefinitionKey?: string;
    WorkRequestKey?: string;
    WorkspaceId?: number;
  };
};

export type FlowEventData = {
  data: Record<string, any>;
  info: FlowEvent;
  workspace: {
    Id: number;
    SubscriberKey: string;
    Name: string;
    CosmosDbPartitionKey: string;
  };
};

export type EventPayloadFilter = {
  operator?: string;
  propertyName?: string;
  value?: string | string[];
  dataPrefix?: string;
  $id?: string;
};

export type FlowActionSummary = {
  id: string;
  status: string;
  context?: {
    closed: {
      'service-runner-job-id': string;
    };
  };
  createdUtc: string;
  modifiedUtc: string;
  actionPath: string;
  dataObjectUrlPart: string;
  actionUrlPart: string;
  app: string;
};

export type FlowRunActions = {
  pageIndex: number;
  pageTotal: number;
  total: number;
  pageSize: number;
  data: FlowActionSummary[];
};

export type FlowRunStatePeformanceMetrics = {
  peakMemoryInKiloBytes: number;
  processTimeInSeconds: number;
  score: number;
  numberOfSteps: number;
};

export type PostFlowContainerTemplateBody = {
  name?: string;
  description?: string;
  containerIdToClone: number;
  accountId: number;
};

export type DeploymentSlotName = 'main' | 'staging';

export type FlowContainerRegistration = {
  configurationJson: string | null;
  id: number;
  subscriberId: number;
  createdDate: string;
  modifiedDate: string;
  archivedDate: string | null;
  isActive: boolean;
  ryvitFlowContainerId: number;
  ryvitFlowContainer: FlowContainer | null;
  integrationFeatureResourceId: number;
  useStaged: boolean;
};

type FlowActionStatus =
  | 'Queued'
  | 'Processing'
  | 'Failed'
  | 'Successful'
  | string;

export type FlowSearchParams = BasePaginationParams & {
  orderBy?: string;
  ascending?: boolean;
  containerId?: number;
  flowContainerIds?: number[];
  hasSchedules?: boolean;
  hasUnpublishedDraft?: boolean;
  includeArchived?: boolean;
  isActive?: boolean;
  isCallable?: boolean;
  isPublished?: boolean;
  isShareable?: boolean;
  isRemediation?: boolean;
  isTemplate?: boolean;
  name?: string;
  path?: string;
  subscriberId?: number;
  triggerType?: FlowTriggerEventType;
  accountId?: number;
  workRequestDefinitionKey?: string;
  workItemRequestCollectionKey?: string;
  definitionType?: 'main' | 'staging';
  workspaceName?: string;
};

export type FlowInstanceActionsParams = {
  subscriberId: number;
  statuses?: FlowActionStatus | FlowActionStatus[];
  actionPath?: string;
  pageNumber?: number;
  pageSize?: number;
};

export type FlowRegistrationCreate = {
  subscriberId: number;
  containerId: number;
  isActive: boolean;
};

export type FlowRegistrationUpdate = {
  configurationJson?: Record<string, unknown> | null;
};
