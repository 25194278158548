import { useAuth } from 'react-oidc-context';
import { getUserNameFromToken } from '../helpers';

export function useUsername(): string {
  const auth = useAuth();
  const token = auth?.user?.access_token;

  return getUserNameFromToken(token);
}

export default useUsername;
