import { ActivityLog } from 'features/activityLogs/types';
import { Connector } from './connectorTypes';

export type ConnectionPathParam = {
  $connectionId: string;
};

export type ConnectionDefinition = {
  connectorKey: string;
  key: string;
  name: string;
  description?: string;
  attributes: DefinitionAttribute[];
};

export type DefinitionAttribute = {
  key: string;
  title: string;
  isSensitive: boolean;
  isRequired?: boolean;
  description?: string;
  options: { label: string; value: number; description?: string }[] | null;
};

type ConnectionConnector = Pick<Connector, 'id' | 'key' | 'name'>;

export type Connection = {
  id: string;
  accountId: number;
  name: string;
  isConnected: boolean;
  connector: ConnectionConnector;
  lastTestResult: ConnectionTestResultDetails | null;
  connectionDefinitionKey: string;
  createdBy: string;
  createdOn: string;
  configuration: Record<string, any>;
};

export type ConnectionsListItem = Omit<Connection, 'configuration'>;

export type ConnectionTestResultDetails = {
  id: string;
  timestamp: string;
  result: ConnectionTestResult;
  details: string | null;
  testedBy: string;
};

export enum ConnectionTestResult {
  Queued = 'queued',
  Pass = 'pass',
  Fail = 'fail',
  Indeterminate = 'indeterminate',
}

export type ConnectionSummary = {
  id: string;
  name: string;
  isConnected: boolean;
  createdBy: string;
};

export type PostConnectionPayload = {
  connectionDefinitionKey: string;
  connectorId: string;
  accountId: number;
  name: string;
  configuration: Record<string, any>;
  connectorRegistrationId?: number;
};

export type PutConnectionPayload = {
  name: string;
  configuration?: Record<string, any>;
};

export enum ConnectionActivity {
  Updated = 'Connection Updated',
  Deleted = 'Connection Deleted',
  Connected = 'Connection Connected',
  Removed = 'Connection Removed',
  Disconnected = 'Connection Disconnected',
}

export type ConnectionActivityLogItem = ActivityLog & {
  domain: 'Connection';
  oldValues?: Connection;
  newValues?: Connection;
  activity: ConnectionActivity;
  connectionId: string;
  connectorRegistrationId: number;
};
