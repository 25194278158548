// DO NOT COMMIT CHANGES TO THIS FILE. This file is for local development only.

// CAUTION: IMPORTING MOCK DATA
// Importing from `src/api/__tests__` will cause issues with the localhost.

// Declare all mocks here.

/* 
Example usage to access path params and return a mock response:

const handlers = [
  createMockHandler(
    getAccount({ $accountId: ':accountId' as any }),
    ({ params }) => ({
      id: Number(params.accountId),
      name: 'Mock Account' + params.accountId,
      createdOn: '',
      createdBy: '',
    }),
  ),
]

Example for a straight mock response:

const handlers = [
  createMockHandler(
    getAccount({ $accountId: ':accountId' as any }),
    {
      id: 1,
      name: 'Mock Account',
      createdOn: '',
      createdBy: '',
    },
  ),
]
*/

export const handlers = [];
