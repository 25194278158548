import { QuickFilters, QuickFilterValue } from '@x/types';
import dayjs from 'dayjs';

export const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;

export const BILLING_PERIODS = [
  { label: 'Yearly', value: 315360000000000 },
  { label: 'Semi-yearly', value: 157680000000000 },
  { label: 'Monthly', value: 25920000000000 },
  { label: 'Bi-weekly', value: 12096000000000 },
  { label: 'Weekly', value: 6048000000000 },
  { label: 'Daily', value: 864000000000 },
];

export const intervals = [
  { label: 'Once', value: 'Once' },
  { label: 'Minute', value: 'Minute' },
  { label: 'Hour', value: 'Hour' },
  { label: 'Day', value: 'Day' },
  { label: 'Month', value: 'Month' },
];

export const IANAToTimeZoneName: Record<string, string> = {
  Greenwich: 'Dateline Standard Time',
  'America/Adak': 'Aleutian Standard Time',
  'America/Anchorage': 'Alaskan Standard Time',
  'America/Boise': 'Mountain Standard Time',
  'America/Bogota': 'SA Pacific Standard Time',
  'America/Chicago': 'Central Standard Time',
  'America/Chihuahua': 'Mountain Standard Time (Mexico)',
  'America/Denver': 'Mountain Standard Time',
  'America/Detroit': 'Eastern Standard Time',
  'America/Grand_Turk': 'Turks And Caicos Standard Time',
  'America/Havana': 'Cuba Standard Time',
  'America/Indiana/Indianapolis': 'US Eastern Standard Time',
  'America/Indiana/Knox': 'Central Standard Time',
  'America/Indiana/Marengo': 'Eastern Standard Time',
  'America/Indiana/Petersburg': 'Eastern Standard Time',
  'America/Indiana/Tell_City': 'Central Standard Time',
  'America/Indiana/Vevay': 'Eastern Standard Time',
  'America/Indiana/Vincennes': 'Eastern Standard Time',
  'America/Indiana/Winamac': 'Eastern Standard Time',
  'America/Juneau': 'Alaska Standard Time',
  'America/Kentucky/Louisville': 'Eastern Standard Time',
  'America/Kentucky/Monticello': 'Eastern Standard Time',
  'America/Los_Angeles': 'Pacific Standard Time',
  'America/Lima': 'SA Pacific Standard Time',
  'America/Menominee': 'Central Standard Time',
  'America/Metlakatla': 'Alaska Standard Time',
  'America/Mexico_City': 'Central Standard Time (Mexico)',
  'America/New_York': 'Eastern Standard Time',
  'America/Nome': 'Alaska Standard Time',
  'America/North_Dakota/Beulah': 'Central Standard Time',
  'America/North_Dakota/Center': 'Central Standard Time',
  'America/North_Dakota/New_Salem': 'Central Standard Time',
  'America/Phoenix': 'Mountain Standard Time',
  'America/Sitka': 'Alaska Standard Time',
  'America/Yakutat': 'Alaska Standard Time',
  'Canada/Saskatchewan': 'Canada Central Standard Time',
  'Canada/Yukon': 'Yukon Standard Time',
  Cuba: 'Cuba Standard Time',
  'Mexico/BajaSur': 'Pacific Standard Time (Mexico)',
  'Pacific/Bougainville': 'UTC-11',
  'Pacific/Gambier': 'UTC-09',
  'Pacific/Honolulu': 'Hawaiian Standard Time',
  'Pacific/Kosrae': 'UTC-11',
  'Pacific/Marquesas': 'Marquesas Standard Time',
  'Pacific/Pitcairn': 'UTC-08',
  'Pacific/Pohnpei': 'UTC-11',
  'US/Alaska': 'UTC-09',
  'US/Arizona': 'US Mountain Standard Time',
  'US/Central': 'Central Standard Time',
  'Pacific/Easter': 'Easter Island Standard Time',
};

export function dateMinus(
  amount: number,
  unit?: dayjs.ManipulateType, // moment.unitOfTime.DurationConstructor,
): string {
  // battling lint rules makes this needed
  // eslint-disable-next-line
  return dayjs().subtract(amount, unit).utc().format().replace('Z', '+00:00');
}

export function startOfDay(): string {
  // battling lint rules makes this needed
  // eslint-disable-next-line
  return dayjs().startOf('day').utc().format().replace('Z', '+00:00');
}

export const quickFilters: {
  display: QuickFilters;
  label: QuickFilters;
  value: QuickFilterValue;
  convertForServer: () => string;
}[] = [
  {
    display: QuickFilters.lastHour,
    label: QuickFilters.lastHour,
    value: 'lastHour',
    convertForServer: (): string => dateMinus(1, 'hour'),
  },
  {
    display: QuickFilters.last4Hours,
    label: QuickFilters.last4Hours,
    value: 'last4Hours',
    convertForServer: (): string => dateMinus(4, 'hours'),
  },
  {
    display: QuickFilters.last12Hours,
    label: QuickFilters.last12Hours,
    value: 'last12Hours',
    convertForServer: (): string => dateMinus(12, 'hours'),
  },
  {
    display: QuickFilters.today,
    label: QuickFilters.today,
    value: 'today',
    convertForServer: startOfDay,
  },
  {
    display: QuickFilters.last24Hours,
    label: QuickFilters.last24Hours,
    value: 'last24Hours',
    convertForServer: (): string => dateMinus(24, 'hours'),
  },
  {
    display: QuickFilters.last7Days,
    label: QuickFilters.last7Days,
    value: 'last7Days',
    convertForServer: (): string => dateMinus(7, 'days'),
  },
  {
    display: QuickFilters.last30Days,
    label: QuickFilters.last30Days,
    value: 'last30Days',
    convertForServer: (): string => dateMinus(30, 'days'),
  },
];
