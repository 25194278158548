/* eslint-disable no-redeclare */ // TODO clear
import {
  BasePaginationParams,
  PaginatedCursorResponse,
  PaginatedNextRowKeyResponse,
  PaginatedResponse,
  PaginationCursorParams,
  PaginationNextRowKeyParams,
} from '@x/types';
import { axios } from 'lib/axios';
import * as R from 'ramda';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQueries,
  useQuery,
  UseQueryResult,
} from 'react-query';
import { INVALID_URL, QUERY_OPTION_KEYS } from '../config';
import { ApiQueryConfig } from '../types';
import { getAxiosOptions, getQueryKey } from '../utils';

const getEnabled = R.ifElse(
  R.propEq('url', INVALID_URL),
  R.F,
  R.prop('enabled'),
);

function makeQuery<T1, T2, T3, T4, T5>(
  config: ApiQueryConfig<T1, T2, T3, T4, T5>,
) {
  const queryOptions = R.pick(QUERY_OPTION_KEYS, config);
  const axiosOptions = getAxiosOptions(config);
  const enabled = getEnabled({
    url: axiosOptions.url,
    enabled: queryOptions.enabled,
  });

  return {
    ...queryOptions,
    enabled,
    queryKey: getQueryKey(axiosOptions),
    queryFn: async () => await axios(axiosOptions),
  };
}

export function useAxiosQuery<
  TResponseData,
  TPathParams,
  TQueryParams,
  TRequestBody,
  TRawResponseData = TResponseData,
>(
  config: ApiQueryConfig<
    TResponseData,
    TPathParams,
    TQueryParams,
    TRequestBody,
    TRawResponseData
  >,
): UseQueryResult<TResponseData> {
  const query = makeQuery(config);

  return useQuery<TRawResponseData, unknown, TResponseData>(query);
}

export function useAxiosQueries<
  TResponseData,
  TPathParams,
  TQueryParams,
  TRequestBody,
  TRawResponseData = TResponseData,
>(
  configs: ApiQueryConfig<
    TResponseData,
    TPathParams,
    TQueryParams,
    TRequestBody,
    TRawResponseData
  >[],
): UseQueryResult<TResponseData>[] {
  const queries = configs.map(makeQuery);

  return useQueries(queries) as UseQueryResult<TResponseData>[];
}

export function useAxiosInfiniteQuery<
  TResponseData extends PaginatedResponse<unknown>,
  TPathParams,
  TQueryParams,
  TRequestBody extends BasePaginationParams,
  TRawResponseData = TResponseData,
>(
  config: ApiQueryConfig<
    TResponseData,
    TPathParams,
    TQueryParams,
    TRequestBody,
    TRawResponseData
  >,
  infiniteQueryOptions: { pageParamLocation: 'body' },
): UseInfiniteQueryResult<TResponseData>;

export function useAxiosInfiniteQuery<
  TResponseData extends PaginatedResponse<unknown>,
  TPathParams,
  TQueryParams extends BasePaginationParams,
  TRequestBody,
  TRawResponseData = TResponseData,
>(
  config: ApiQueryConfig<
    TResponseData,
    TPathParams,
    TQueryParams,
    TRequestBody,
    TRawResponseData
  >,
  infiniteQueryOptions?: { pageParamLocation?: 'query' },
): UseInfiniteQueryResult<TResponseData>;

export function useAxiosInfiniteQuery<
  TResponseData extends PaginatedResponse<unknown>,
  TPathParams,
  TQueryParams extends BasePaginationParams,
  TRequestBody extends BasePaginationParams,
  TRawResponseData = TResponseData,
>(
  config: ApiQueryConfig<
    TResponseData,
    TPathParams,
    TQueryParams,
    TRequestBody,
    TRawResponseData
  >,
  infiniteQueryOptions?: { pageParamLocation?: 'query' | 'body' },
): UseInfiniteQueryResult<TResponseData> {
  const queryOptions = R.pick(QUERY_OPTION_KEYS, config);
  const axiosOptions = getAxiosOptions(config);
  const enabled = getEnabled({
    url: axiosOptions.url,
    enabled: queryOptions.enabled,
  });

  const pageParamProperty =
    infiniteQueryOptions?.pageParamLocation === 'body' ? 'data' : 'params';

  const defaultPageNumber = config[pageParamProperty]?.pageNumber ?? 1;
  const queryKey = getQueryKey(axiosOptions);
  const queryFn = ({ pageParam = defaultPageNumber }) =>
    axios(
      R.assocPath([pageParamProperty, 'pageNumber'], pageParam, axiosOptions),
    );
  const getNextPageParam = (lastPage: TResponseData) =>
    lastPage.pageIndex < lastPage.pageTotal
      ? lastPage.pageIndex + 1
      : undefined;

  return useInfiniteQuery(queryKey, {
    ...queryOptions,
    enabled,
    queryFn,
    getNextPageParam,
  });
}

export function useAxiosInfiniteCursorQuery<
  TResponseData extends PaginatedCursorResponse<unknown>,
  TPathParams,
  TQueryParams extends PaginationCursorParams,
  TRequestBody,
>(
  config: ApiQueryConfig<
    TResponseData,
    TPathParams,
    TQueryParams,
    TRequestBody
  >,
): UseInfiniteQueryResult<TResponseData> {
  const queryKey = getQueryKey(config);
  const queryOptions = R.pick(QUERY_OPTION_KEYS, config);
  const axiosOptions = getAxiosOptions(config);
  const enabled = getEnabled({
    url: axiosOptions.url,
    enabled: queryOptions.enabled,
  });

  const queryFn = ({ pageParam = null }) =>
    axios(R.assocPath(['params', 'cursor'], pageParam, axiosOptions));
  const getNextPageParam = (lastPage: TResponseData) => lastPage.cursor.next;

  return useInfiniteQuery(queryKey, {
    ...queryOptions,
    enabled,
    queryFn,
    getNextPageParam,
  });
}

export function useAxiosInfiniteNextRowKeyQuery<
  TResponseData extends PaginatedNextRowKeyResponse<unknown>,
  TPathParams,
  TQueryParams extends PaginationNextRowKeyParams,
  TRequestBody,
>(
  config: ApiQueryConfig<
    TResponseData,
    TPathParams,
    TQueryParams,
    TRequestBody
  >,
): UseInfiniteQueryResult<TResponseData> {
  const queryKey = getQueryKey(config);
  const queryOptions = R.pick(QUERY_OPTION_KEYS, config);
  const axiosOptions = getAxiosOptions(config);
  const enabled = getEnabled({
    url: axiosOptions.url,
    enabled: queryOptions.enabled,
  });

  const queryFn = ({ pageParam = null }) =>
    axios(R.assocPath(['params', 'nextRowKey'], pageParam, axiosOptions));
  const getNextPageParam = (lastPage: TResponseData) => lastPage.nextRowKey;

  return useInfiniteQuery(queryKey, {
    ...queryOptions,
    enabled,
    queryFn,
    getNextPageParam,
  });
}
