export const connectionTypes = [
  { value: '1', label: 'SQL' },
  { value: '2', label: 'ODBC' },
  { value: '3', label: 'OLEDB' },
];

export const connectionStrings = {
  // SQL
  '1': 'Server=SERVER;Database=DATABASE;User Id=USERID;Password=PASSWORD;',
  // ODBC
  '2': 'Driver={SQL Server};Server=SERVER;Database=DATABASE;Uid=USERID;Pwd=PASSWORD;',
  // OLEDB
  '3': 'Provider=Microsoft.ACE.OLEDB.12.0;DataSource=C:\\myFolder\\myAccessFile.mdb;Jet OLEDB:DatabasePassword=PASSWORD;',
};

// all lowercase as they are case-insensitive and added casing will break verification
export const sqlVerifiers = [
  'server',
  'database',
  'user id',
  'user',
  'password',
  'data source',
  'initial catalog',
  'connection timeout',
  'multipleactiveresultsets',
];
export const odbcVerifiers = [
  'dsn',
  'uid',
  'pwd',
  'pooling',
  'driver',
  'dbq',
  'codepage',
  'dictionarymode',
  'standardmode',
  'maxcolsupport',
  'shortnames',
  'databasetype',
];
export const oledbVerifiers = ['datasource', 'version', 'password'];

export const triggerExpressionTypes = [
  { label: 'equal to', smallLabel: '=', value: 'Equal' },
  { label: 'greater than', smallLabel: '>', value: 'GreaterThan' },
  {
    label: 'greater than or equal to',
    smallLabel: '>=',
    value: 'GreaterThanOrEqual',
  },
  { label: 'less than', smallLabel: '<', value: 'LessThan' },
  {
    label: 'less than or equal to',
    smallLabel: '<=',
    value: 'LessThanOrEqual',
  },
  { label: 'not equal to', smallLabel: '!=', value: 'NotEqual' },
];

export const expressionTypes = [
  ...triggerExpressionTypes,
  { label: 'in', smallLabel: 'in', value: 'In' },
  { label: 'like', smallLabel: 'like', value: 'Like' },
  { label: 'contains', smallLabel: 'contains', value: 'Contains' },
  { label: 'starts with', smallLabel: 'starts with', value: 'StartsWith' },
  { label: 'ends with', smallLabel: 'ends with', value: 'EndsWith' },
];

export const cacheEventTypes = [
  { label: 'Create', value: 'create' },
  { label: 'Update', value: 'update' },
  { label: 'Delete', value: 'delete' },
];

export const actionResponseEventTypes = [
  { label: 'Any', value: 'Any' },
  { label: 'Successful', value: 'Successful' },
  { label: 'Failed', value: 'Failed' },
];
