import { PaginatedResponse } from '@x/types';
import { URLS } from 'config';
import { ApiMutationConfig, ApiQueryConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import {
  Account,
  GetAccountsQueryParams,
  UpsertAccountRequest,
} from '../types';

type GetAccountsConfig = ApiQueryConfig<
  PaginatedResponse<Account>,
  unknown,
  GetAccountsQueryParams
>;

type PostAccountsConfig = ApiMutationConfig<
  UpsertAccountRequest,
  unknown,
  Account
>;

const url = `${URLS.account}/v1/accounts`;

export const getAccounts = createApiEndpoint<GetAccountsConfig>({
  method: 'GET',
  url,
  params: { pageSize: 25 },
});

export const postAccounts = createApiEndpoint<PostAccountsConfig>({
  method: 'POST',
  url,
});
