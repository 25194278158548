import { JobType } from '@x/types';

export type JobSequence = {
  $id: string;
  DateRules?: any;
  Id: number;
  Sequence: number;
  JobType: JobType;
  ResourceId: string;
  ContinueWithoutFinish: boolean;
};

export type SequenceScheduleDateTimeInfo = {
  $id: string;
  Active: boolean;
  Id: number;
  Interval: number;
  IntervalType: string;
  StartDateTime: string;
  TimeZone: string;
};

type ServiceConfiguration = {
  CanEditServiceConfigurations: boolean;
  Configuration: string | null;
  ConfigurationSchema: string | null;
  Description: string | null;
  Id: number;
  OrganizationName: string;
  Organization_Id: number;
  ServiceName: string;
  Service_Id: number;
  SystemName: string;
  serviceName?: string;
  serviceNameLower?: string;
  serviceId?: number;
  orgId?: number;
  IntegrationFeatureResourceId?: number;
};

export type ServicesScheduleSequence = {
  $id: string;
  DateRules?: any;
  Id: number;
  Sequence: number;
  ServiceConfiguration: ServiceConfiguration;
  ServiceConfiguration_Id?: number;
  ServiceName?: string;
  OrganizationName?: string;
};

type ServiceSequenceSchedule = {
  $id?: string;
  Active: boolean;
  DateTimeInfos: SequenceScheduleDateTimeInfo[];
  Description: string;
  Id: number;
  MinutesToWaitBeforeStartingNewScheduleIfPreviousRunIsNotFinished?:
    | number
    | null;
  OrganizationId?: number;
  ServiceScheduleSequences: ServicesScheduleSequence[];
  JobSequences: JobSequence[];
  IntegrationFeatureResourceId?: number;
};

export enum IntegrationScheduleType {
  Unknown = 0,
  Onboarding = 1,
  Active = 2,
  Maintenance = 3,
}

export type IntegrationScheduleMetadata = {
  IntegrationId: number;
  IntegrationRegistrationId: number;
  IntegrationResourceId: number;
  ScheduleType: IntegrationScheduleType;
};

export type SequenceSchedule = {
  $id?: string;
  Active: boolean;
  IsRunning: boolean;
  LastRunStartDateTimeUtc: string;
  LastRunStatus: string;
  MinutesToWaitBeforeStartingNewScheduleIfPreviousRunIsNotFinished:
    | number
    | null;
  OrganizationId: number;
  ServiceSequenceSchedule: ServiceSequenceSchedule;
  IntegrationMetadata?: IntegrationScheduleMetadata;
};
