function createLegacyPermissions(scope: string) {
  return {
    read: `read:${scope}`,
    create: `create:${scope}`,
    update: `update:${scope}`,
    delete: `delete:${scope}`,
  };
}

function createPermissions(scope: string) {
  return {
    read: `${scope}:read`,
    create: `${scope}:create`,
    update: `${scope}:update`,
    delete: `${scope}:delete`,
    manage: `${scope}:manage`,
  };
}

const baseFlowBuildPerms = createLegacyPermissions('flow-build');

export const flowBuildPerms = {
  ...baseFlowBuildPerms,
  languageCsharp: 'flow-build:language-csharp',
  manageEngine: 'flow-build:manage-engine',
};
export const flowOpsPerms = createLegacyPermissions('flow-ops');
export const appNetExpPerms = createLegacyPermissions('appnetexp');
export const intBuildPerms = createLegacyPermissions('int-build');
export const intOpsPerms = createLegacyPermissions('int-ops');
export const accountPerms = createLegacyPermissions('account');
export const platformPerms = createLegacyPermissions('platform');
export const appBuilderPerms = createLegacyPermissions('appbuilder');
export const conBuildPerms = createPermissions('con-build');
export const conOpsPerms = createPermissions('con-ops');
