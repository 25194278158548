import { Card, Space, Typography } from 'antd';
import { UnauthorizedPage } from 'components/Layout/UnauthorizedPage';
import React from 'react';
import { Link } from 'react-router-dom';

export function ExpiredInvite({
  message,
}: {
  message?: string;
}): React.JSX.Element {
  return (
    <UnauthorizedPage top={'35%'}>
      <Card style={{ textAlign: 'center' }}>
        <Space direction="vertical">
          <Typography.Title level={3}>Link Expired!</Typography.Title>
          {message && (
            <Typography.Text type="secondary">{message}</Typography.Text>
          )}
          <Typography
            style={{
              fontWeight: 'lighter',
              paddingBottom: 20,
              textAlign: 'center',
            }}
          >
            Already have an account? <Link to="/">Sign in</Link>
          </Typography>
        </Space>
      </Card>
    </UnauthorizedPage>
  );
}
