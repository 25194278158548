import { URLS } from 'config';
import { ApiMutationConfig } from '../../types';
import { createApiEndpoint } from '../../utils';
import { UserPathParams } from '../types';

type PutPasswordResetBody = { token: string; password: string };
type PutUserPasswordResetConfig = ApiMutationConfig<
  PutPasswordResetBody,
  UserPathParams
>;

const url = `${URLS.account}/v1/users/$userId/password/reset`;

export const putUserPasswordReset =
  createApiEndpoint<PutUserPasswordResetConfig>({
    url,
    method: 'PUT',
  });
