import { ApiKeyListItem, PaginatedResponse } from '@x/types';
import { ApiQueryConfig } from 'api/types';
import { createApiEndpoint } from 'api/utils';
import { URLS } from 'config';

type GetApiKeysPathParams = {
  PageNumber?: number;
  PageSize?: number;
  Search?: string;
  OrderBy?: string;
  Ascending?: boolean;
};

type GetApiKeysConfig = ApiQueryConfig<
  PaginatedResponse<ApiKeyListItem>,
  unknown,
  GetApiKeysPathParams
>;

/*
 * Returns a list of api keys that incorporates the workspace display name.
 * For use only in the ApiKeysTable.
 */
export const getApiKeys = createApiEndpoint<GetApiKeysConfig>({
  url: `${URLS.account}/v1/workspaces/api-keys`,
  method: 'GET',
});
